<template>
  <b-overlay :variant="skin" :show="isloading || isloadingSecondary" spinner-variant="primary" spinner-type="grow">
    <app-collapse v-if="this.calendarOptions &&
        this.calendarOptions.events &&
        this.calendarOptions.events.filter((f) => f.conflited).length > 0
        ? true
        : false
      " type="border" class="mb-2">
      <app-collapse-item title="AGENDAMENTOS EM CONFLITO" style="background-color: #fbdddd; color: #ea5455 !important">
        <table style="width: 80%">
          <thead>
            <tr>
              <th>Cód.</th>
              <th>Conflitantes</th>
              <th>Data</th>
              <th>Grupo</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(_event, ievent) in calendarOptions.events.filter(
              (f) => f.conflited
            )" :key="ievent">
              <td>
                <span class="cursor-pointer" @click="onClickByCode(_event.id)">#{{ _event.id }}</span>
              </td>
              <td>{{ _event.conflited_ids }}</td>
              <td>{{ _event.start | dateFormat }}</td>
              <td>
                <div v-if="_event.groupstudios && _event.groupstudios.length > 0">
                  {{ _event.groupstudios[0].name }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </app-collapse-item>
    </app-collapse>

    <div class="app-calendar overflow-hidden border">
      <div class="row no-gutters">
        <!-- Calendar -->
        <div class="col position-relative">
          <div class="card shadow-none border-0 mb-0 rounded-0">
            <div class="card-body pb-0">
              <b-form class="selector-button" inline>
                <b-form-select style="font-weight: bold" class="mr-1" :options="monthOptions"
                  v-model="currentMonthOption" />
                <b-form-select style="font-weight: bold" :options="yearOptions" v-model="currentYearOption" />
              </b-form>
              <full-calendar ref="fullCalendar" :options="calendarOptions" class="full-calendar"
                id="full-calendar-appoitment-list" />
            </div>
          </div>
        </div>

        <!-- Details appointment -->
        <b-sidebar id="sidebar-details-appointment" bg-variant="white" v-model="isActiveDetails" right backdrop shadow
          no-header>
          <template #default="{ hide }" v-if="isActiveDetails">
            <div class="content-sidebar-header px-1 py-75">
              <div class="row">
                <div class="col-12" style="display: flex; align-items: center">
                  <h4 class="mb-0">
                    Detalhes Agendamento #{{ appointmentid.id }}
                  </h4>
                  <div style="display: flex; flex-grow: 1; justify-content: end; align-items: flex-end">
                    <div style="display: flex; justify-content: end; margin-right: 10px">
                      <div v-if="appointmentid.active && !appointmentid.is_pasted && !calendarReadOnly" @cancel="() => {
                          appointmentid.active = false;
                        }
                        ">
                        <b-button v-if="rescheduleIsEnable()" variant="info"
                          class="btn-icon mr-1" @click="reschedule(appointmentid.id)">
                          Reagendar
                        </b-button>
                        <!-- <b-button v-if="rescheduleBetaIsEnable()" variant="info" class="btn-icon mr-1"
                          @click="rescheduleBeta(appointmentid.id)">
                          Reagendar - BETA
                        </b-button> -->
                        <b-button v-if="$utils.isPermited('permission.appointment.delete')" variant="danger"
                          class="btn-icon" v-b-modal.modal-cancelar-no-prazo>
                          Cancelar
                        </b-button>
                      </div>
                    </div>
                    <div style="
                        background: #fff;
                        border-radius: 50%;
                        padding: 8px;
                        color: #000;
                        text-align: center;
                        width: fit-content;
                      ">
                      <feather-icon class="cursor-pointer" icon="XIcon" size="20" @click="hide" />
                    </div>
                  </div>
                </div>

                <!-- tem que ter a permissão, precisa ja ter passado a data do evento, e não pode ter studios "SALA VIP" -->
                <div class="col-5" style="display: flex; justify-content: end" v-if="$utils.isPermited(
                  'permission.appointment.cancel.out.studio'
                ) &&
                  appointmentid.is_pasted &&
                  !appointmentid.is_contains_studio &&
                  !calendarReadOnly
                  ">
                  <b-button v-if="$utils.isPermited('permission.appointment.delete')" variant="danger" class="btn-icon"
                    v-b-modal.modal-cancelar-no-prazo>
                    Cancelar
                  </b-button>
                </div>

                <div class="col-6">
                  <Tracking v-if="appointmentid && appointmentid.id > 0" :id="{ appointment_id: appointmentid.id }"
                    size="20" class="mt-25" />
                </div>
              </div>
            </div>
            <v-appointment v-if="appointmentid && appointmentid.id > 0" :appointment="appointmentid"
              :calendarReadOnly="calendarReadOnly" />
          </template>
        </b-sidebar>

        <!-- Filter -->
        <b-button :disabled="isloading" class="btn-filter-appoitment btn-icon rounded-circle"
          :variant="containsFilter ? 'gradient-warning' : 'gradient-info'" @click="() => {
              filterbycode = '';
              isActiveFilter = true;
            }
            ">
          <feather-icon icon="FilterIcon" size="25" />
        </b-button>

        <b-sidebar sidebar-class="sidebar-lg" bg-variant="white" v-model="isActiveFilter" right backdrop shadow no-header>
          <template #default="{ hide }" v-if="isActiveFilter">
            <div class="
                d-flex
                justify-content-between
                align-items-center
                content-sidebar-header
                px-2
                py-1
              ">
              <h5 class="mb-0">Filtros</h5>
              <div>
                <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
              </div>
            </div>
            <b-form class="px-1 mt-1">
              <b-row class="pb-1">
                <b-col cols="12">
                  <b-form-group label="Código/ID do Agendamento">
                    <b-input-group>
                      <b-form-input placeholder="Digite o código..." autocomplete="off" v-model="filterbycode" />
                      <b-input-group-append>
                        <b-button variant="gradient-info" @click="onClickByCode(0)">
                          <feather-icon icon="SearchIcon" />
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <div v-if="!calendarReadOnly">

                <b-row class="mb-1">
                  <b-col cols="12">
                    <b-form-group label="Empresa">
                      <v-select v-if="companies.length > 0" v-model="companieSelected"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="companies" autocomplete="off"
                        multiple :clearable="true" @input="changefilter(companieSelected)">
                        <template v-slot:option="option">
                          <div class="d-flex justify-content-between">
                            <span class="mb-0 text-capitalize">
                              {{ option.label }}
                            </span>
                            <b-avatar v-if="option.color && option.qtd > 0"
                              :style="`z-index: 1; background: ${option.color}`" :text="option.qtd.toString()"
                              size="sm" />
                          </div>
                        </template>
                      </v-select>
                    </b-form-group>
                  </b-col>

                  <b-col cols="12">
                    <b-form-group label="Projetos">
                      <v-select v-if="projects.length > 0" v-model="projectSelected"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="projects" autocomplete="off"
                        :clearable="true" multiple @input="changefilter(projectSelected)">
                        <template v-slot:option="option">
                          <div class="d-flex justify-content-between">
                            <span class="mb-0 pr-1 text-capitalize text-truncate">
                              {{ option.label }}
                            </span>
                            <b-avatar v-if="option.color && option.qtd > 0"
                              :style="`z-index: 1; background: ${option.color}`" :text="option.qtd.toString()"
                              size="sm" />
                          </div>
                        </template>
                      </v-select>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group label="Estúdio (Grupo)">
                      <v-select v-if="studiosGroup.length > 0" v-model="studioGroupSelected"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="studiosGroup" autocomplete="off"
                        :clearable="true" multiple @input="changefilter(studioGroupSelected)">
                        <template v-slot:option="option">
                          <div class="d-flex justify-content-between">
                            <span class="mb-0 text-capitalize">
                              {{ option.label }}
                            </span>
                            <b-avatar v-if="option.qtd > 0 && option.qtd > 0" style="z-index: 1" variant="secondary"
                              :text="option.qtd.toString()" size="sm" />
                          </div>
                        </template>
                      </v-select>
                    </b-form-group>
                    <b-col class="mb-2" cols="12" v-if="studioGroupSelected[0].value != '-1'">
                      <div>
                        <b-form-group label="Estúdio">
                          <v-select v-if="studios.length > 0" v-model="studioSelected"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="studios" autocomplete="off"
                            :clearable="true" multiple @input="changefilter(studioSelected)" class="mb-1">
                            <template v-slot:option="option">
                              <div class="d-flex justify-content-between">
                                <span class="mb-0 text-capitalize">
                                  {{ option.label }}
                                </span>
                                <b-avatar v-if="option.qtd > 0 && option.qtd > 0" style="z-index: 1" variant="secondary"
                                  :text="option.qtd.toString()" size="sm" />
                              </div>
                            </template>
                          </v-select>
                        </b-form-group>
                        <b-form-checkbox v-model="includeStudiosSupport">
                          Incluir estúdios de apoio?
                        </b-form-checkbox>
                      </div>
                    </b-col>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group label="Localidade">
                      <v-select v-if="places.length > 0 && placesSelected" v-model="placesSelected"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="places" autocomplete="off"
                        :clearable="true" multiple @input="changefilter(placesSelected)">
                        <template v-slot:option="option">
                          <div class="d-flex justify-content-between">
                            <span class="mb-0 text-capitalize">
                              {{ option.label }}
                            </span>
                          </div>
                        </template>
                      </v-select>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group label="Tipo de Transmissão">
                      <v-select v-if="transmissions_types.length > 0 && transmissionsSelected
                        " v-model="transmissionsSelected" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="transmissions_types" autocomplete="off" multiple :clearable="true"
                        @input="changefilter(transmissionsSelected)">
                        <template v-slot:option="option">
                          <div class="d-flex justify-content-between">
                            <span class="mb-0 text-capitalize">
                              {{ option.label }}
                            </span>
                            <b-avatar v-if="option.color" :style="`z-index: 1; background: ${option.color}`"
                              :text="option.qtd" size="sm" />
                          </div>
                        </template>
                      </v-select>
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" v-if="jobTypes.length > 0">
                    <b-form-group label="Tipo de Agendamento">
                      <v-select v-model="jobTypesSelected" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="jobTypes" autocomplete="off" multiple :clearable="true"
                        @input="changefilter(jobTypesSelected)">
                        <template v-slot:option="option">
                          <div class="d-flex justify-content-between">
                            <span class="mb-0 text-capitalize">
                              {{ option.label }}
                            </span>
                            <b-avatar v-if="option.color" :style="`z-index: 1; background: ${option.color}`"
                              :text="option.qtd" size="sm" />
                          </div>
                        </template>
                      </v-select>
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" v-if="teachers.length > 0">
                    <b-form-group label="Professores">
                      <v-select v-model="teachersSelected" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="teachers" autocomplete="off" multiple :clearable="true"
                        @input="changefilter(teachersSelected)">
                        <template v-slot:option="option">
                          <div class="d-flex justify-content-between">
                            <span class="mb-0 text-capitalize">
                              {{ option.label }}
                            </span>
                            <b-avatar v-if="option.color" :style="`z-index: 1; background: ${option.color}`"
                              :text="option.qtd.toString()" size="sm" />
                          </div>
                        </template>
                      </v-select>
                    </b-form-group>
                  </b-col>

                  <b-col cols="6">
                    <b-form-group label="Operadores">
                      <v-select v-if="operators.length > 0 && operatorsSelected" v-model="operatorsSelected"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="operators" autocomplete="off"
                        :clearable="false">
                        <template v-slot:option="option">
                          <div class="d-flex justify-content-between">
                            <span class="mb-0 text-capitalize">
                              {{ option.label }}
                            </span>
                            <b-avatar v-if="option.qtd > 0" style="z-index: 1" variant="secondary" :text="option.qtd"
                              size="sm" />
                          </div>
                        </template>
                      </v-select>
                    </b-form-group>
                  </b-col>

                  <b-col cols="6">
                    <b-form-group label="Cancelados 3h">
                      <v-select v-if="canceleds.length > 0 && canceledsSelected" v-model="canceledsSelected"
                        :options="canceleds" autocomplete="off" :clearable="false">
                        <template v-slot:option="option">
                          <div class="d-flex justify-content-between">
                            <span class="mb-0 text-capitalize">
                              {{ option.label }}
                            </span>
                            <b-avatar v-if="option.qtd > 0" style="z-index: 1" variant="secondary" :text="option.qtd"
                              size="sm" />
                          </div>
                        </template>
                      </v-select>
                    </b-form-group>
                  </b-col>

                  <b-col cols="6">
                    <b-form-group label="Links de transmissão">
                      <v-select autocomplete="off" v-model="youtubeLinksSelected" :options="youtubeLinks"
                        :clearable="false">
                        <template v-slot:option="option">
                          <div class="d-flex justify-content-between">
                            <span class="mb-0 text-capitalize">
                              {{ option.label }}
                            </span>
                            <b-avatar v-if="option.qtd > 0" style="z-index: 1" variant="secondary" :text="option.qtd"
                              size="sm" />
                          </div>
                        </template>
                      </v-select>
                    </b-form-group>
                  </b-col>

                  <b-col cols="6">
                    <b-form-group label="Visibilidade Final">
                      <v-select v-model="privateOublicSelected" :options="privatesPublics" autocomplete="off"
                        :clearable="false">
                        <template v-slot:option="option">
                          <div class="d-flex justify-content-between">
                            <span class="mb-0 text-capitalize">
                              {{ option.label }}
                            </span>
                            <b-avatar v-if="option.qtd > 0" style="z-index: 1" variant="secondary" :text="option.qtd"
                              size="sm" />
                          </div>
                        </template>
                      </v-select>
                    </b-form-group>
                  </b-col>

                  <b-col cols="6">
                    <b-form-group label="Com Pendência">
                      <v-select autocomplete="off" v-model="hasPendencySelected" :options="hasPendency"
                        :clearable="false">
                        <template v-slot:option="option">
                          <div class="d-flex justify-content-between">
                            <span class="mb-0 text-capitalize">
                              {{ option.label }}
                            </span>
                            <b-avatar v-if="option.qtd > 0" style="z-index: 1" variant="secondary" :text="option.qtd"
                              size="sm" />
                          </div>
                        </template>
                      </v-select>
                    </b-form-group>
                  </b-col>

                  <b-col cols="6">
                    <b-form-group label="Com gravação pendente">
                      <v-select v-model="hasRecordingMediaPendingSelected" :options="hasRecordingMediaPending"
                        autocomplete="off" :clearable="false">
                        <template v-slot:option="option">
                          <div class="d-flex justify-content-between">
                            <span class="mb-0 text-capitalize">
                              {{ option.label }}
                            </span>
                            <b-avatar v-if="option.qtd > 0" style="z-index: 1" variant="secondary" :text="option.qtd"
                              size="sm" />
                          </div>
                        </template>
                      </v-select>
                    </b-form-group>
                  </b-col>

                  <b-col>
                    <b-button size="sm" @click="onclickExibirCancelados" variant="danger" block>
                      {{ showInactive ? "Exibir" : "Ocultar" }} Cancelados
                    </b-button>
                  </b-col>

                  <b-col class="mb-2" cols="6">
                    <b-form-checkbox v-model="onlyHomeoffice">
                      Apenas Home Office
                    </b-form-checkbox>
                  </b-col>

                  <b-col cols="9">
                    <b-button @click="onClickFilter" variant="info" block>
                      FILTRAR
                    </b-button>
                  </b-col>
                  <b-col cols="3">
                    <b-button @click="clearfilter" variant="primary" block>
                      Limpar
                    </b-button>
                  </b-col>
                </b-row>
              </div>
            </b-form>
          </template>
        </b-sidebar>
      </div>
    </div>
    <MoreLinks v-if="showMoreLinks" :title="moreLinks.title" :links="moreLinks.links" :showDetails="isActiveDetails"
      @eventClick="onClickCalendar" @close="showMoreLinks = false" v-focus="outClick" />
  </b-overlay>
</template>
<style lang='css'>
.fc .fc-scrollgrid-section-liquid {
  height: 1px !important;
}
</style>
<script>
import useAppConfig from "@core/app-config/useAppConfig";
import FullCalendar from "@fullcalendar/vue";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import appointment from "./appointment";
import companies from "./companies";
import studios from "./studios";
import MoreLinks from "./components/MoreLinks.vue";
import {
  BInputGroupPrepend,
  BInputGroupAppend,
  BBadge,
  BFormSelect
} from "bootstrap-vue";
import _appointmentService from "@/services/appointment-service";
import _transmissionTypeService from "@/services/audiovisual/transmission-type-service";
import _jobTypeService from "@/services/audiovisual/job-type-service";
import _companyService from "@/services/company-service";
import _projectService from "@/services/projects-service";
import _groupStudiosService from "@/services/studios-group-service";
import _placeService from "@/services/places-service";
import _userService from "@/services/users-service";
import _appointmentLinkService from "@/services/appointment-link-service";

import Tracking from "@/components/Tracking.vue";

export default {
  setup() {
    const { skin } = useAppConfig();
    return {
      skin,
    };
  },
  directives: {
    focus: {
      bind: function (el, binding, vnode) {
        el.clickOutsideEvent = (event) => {
          if (!(el === event.target || el.contains(event.target))) {
            vnode.context[binding.expression](event);
          }
        };
        setTimeout(() => {
          document.body.addEventListener("click", el.clickOutsideEvent);
        }, "100");
      },
      unbind(el) {
        document.body.removeEventListener("click", el.clickOutsideEvent);
      },
    },
  },
  components: {
    MoreLinks,
    AppCollapse,
    AppCollapseItem,
    BInputGroupPrepend,
    BInputGroupAppend,
    FullCalendar,
    BBadge,
    Tracking,
    BFormSelect,
    "v-studios": studios,
    "v-companies": companies,
    "v-appointment": appointment,
  },
  data() {
    return {
      boContainerTeacher: {
        is_active: false,
        teacher_id: null,
        vertical: 'concursos'
      },
      monthOptions: [
        { value: 1, text: "JAN" },
        { value: 2, text: "FEV" },
        { value: 3, text: "MAR" },
        { value: 4, text: "ABR" },
        { value: 5, text: "MAI" },
        { value: 6, text: "JUN" },
        { value: 7, text: "JUL" },
        { value: 8, text: "AGO" },
        { value: 9, text: "SET" },
        { value: 10, text: "OUT" },
        { value: 11, text: "NOV" },
        { value: 12, text: "DEZ" },
      ],
      yearOptions: [],
      showMoreLinks: false,
      showInactive: true,
      onlyHomeoffice: false,
      includeStudiosSupport: false,
      isloading: false,
      isloadingSecondary: false,
      appointmentid: 0,
      isActiveDetails: false,
      isActiveFilter: false,
      filterbycode: "",
      appointments: [],
      currentdate: null,
      calendarFilter: null,
      projects: [],
      projectSelected: [{ label: "Todos", value: "-1" }],
      companies: [],
      companieSelected: [{ label: "Todos", value: "-1" }],
      companies_op: null,
      projects_op: null,
      teachers_op: null,

      studiosGroup: [],
      studioGroupSelected: [{ label: "Todos", value: "-1" }],
      studioSelected: [{ label: "Todos", value: "-1" }],
      jobTypes: [],
      operators: [
        { label: "Todos", value: "-1" },
        { label: "Sem Operadores", value: "1" },
        { label: "Com Operadores", value: "2" },
      ],
      operatorsSelected: { label: "Todos", value: "-1" },
      canceleds: [
        { label: "Todos", value: "-1" },
        { label: "cancelados", value: "1" },
      ],
      canceledsSelected: { label: "Todos", value: "-1" },
      privatesPublics: [],
      privateOublicSelected: { label: "Todos", value: "-1" },
      youtubeLinks: [
        { label: "Todos", value: "-1" },
        { label: "Com Links", value: "1" },
        { label: "Sem Links", value: "2" },
      ],
      youtubeLinksSelected: { label: "Todos", value: "-1" },
      transmissions_types: [],
      transmissionsSelected: [{ label: "Todos", value: "-1" }],
      jobTypesSelected: [{ label: "Todos", value: "-1" }],
      teachers: [],
      teachersSelected: [{ label: "Todos", value: "-1" }],
      hasPendencySelected: [{ label: "Todos", value: "-1" }],
      hasPendency: [
        { label: "Todos", value: "-1" },
        { label: "Sim", value: "true" },
        { label: "Não", value: "false" },
      ],
      hasRecordingMediaPendingSelected: [{ label: "Todos", value: "-1" }],
      hasRecordingMediaPending: [
        { label: "Todos", value: "-1" },
        { label: "Sim", value: "true" },
        { label: "Não", value: "false" },
      ],
      moreLinks: {
        title: "--",
        links: [],
      },
      places: [],
      placesSelected: [{ label: "Todos", value: "-1" }],
      calendarOptions: {
        eventDidMount: (info) => {
          if (
            info.view.type == "timeGridWeek" ||
            info.view.type == "timeGridDay"
          ) {
            let percent = this.getHeightPercent(
              info.event.extendedProps.totalTime,
              info.event.extendedProps.preparationTime
            );
            info.el.innerHTML =
              `<div
              title='Tempo de Preparação'
              style='
                background: repeating-linear-gradient(
                  -45deg,
                  ${info.backgroundColor},
                  ${info.backgroundColor} 10px,
                  rgba(255,255,255,0.4) 10px,
                  rgba(255,255,255,0.4) 20px
                );
                height: ${percent}
              '>
              </div>
            ` + info.el.innerHTML;
          }
        },
        moreLinkClick: (info) => {
          this.moreLinks.links = info.allSegs;
          let day = new Date(info.date).getUTCDate();
          this.moreLinks.title = `${day} de ${info.view.title}`;
          this.showMoreLinks = true;
        },
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
        initialView: window.innerWidth < 700 ? "listMonth" : "dayGridMonth",
        eventTextColor: "#ffffff",
        eventOrder: "relevance",
        eventDisplay: "list-item",
        dayMaxEventRows: true,
        views: {
          timeGrid: {
            dayMaxEventRows: 6,
          },
        },
        headerToolbar: {
          start: "prev, next, title",
          end: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
        },
        buttonText: {
          today: "Hoje",
          month: "Mês",
          week: "Semana",
          day: "Dia",
          list: "Lista",
        },
        locales: ["pt-BR"],
        locale: "pt-BR",
        eventClick: this.onClickCalendar,
        events: [],
        customButtons: {
          dayGridMonth: {
            text: "Mês",
            click: () => {
              this.$refs.fullCalendar.getApi().changeView("dayGridMonth");
            },
          },
          timeGridWeek: {
            text: "Semana",
            click: () => {
              this.$refs.fullCalendar.getApi().changeView("timeGridWeek");
            },
          },
          listMonth: {
            text: "Lista",
            click: () => {              
              this.$refs.fullCalendar.getApi().changeView("listMonth");
            },
          },
          prev: {
            click: (_prev) => {
              this.$refs.fullCalendar.getApi().prev();
              this.changeCurrentDate();
            },
          },
          next: {
            click: (_nxt) => {
              this.$refs.fullCalendar.getApi().next();
              this.changeCurrentDate();
            },
          },
        },
      },
    };
  },
  destroyed() {
    this.$destroy();
  },
  async created() {
    this.setTeacherParams()
    if (this.$route.query.q) {
      this.onClickByCode(this.$route.query.q);
      this.isActiveDetails = true;
    }

    for (let i = -1; i < 4; i++) {
      const currentyear = new Date().getFullYear();
      this.yearOptions.push({
        value: currentyear - i,
        text: (currentyear - i).toString()
      })
    }

    let _datee = new Date();
    _datee.setDate(1);
    this.setCurrentDate(_datee);

    // buscar itens
    this.isloading = true;

    if (this.calendarReadOnly == false) {
      const _promisses = [
        _transmissionTypeService.show(),
        _projectService.showAll(),
        _companyService.showAll(),
        _placeService.autoComplete(),
        _groupStudiosService.showAll(),
        _jobTypeService.autoComplete(),
        _userService.autoComplete(1), // 1 = teachers
        _appointmentLinkService.showVisibilities(),
      ];

      await Promise.all(_promisses).then((_pms) => {
        this.transmissionsTypes(_pms[0]);
        this.populateProjects(_pms[1]);
        this.showCompanies(_pms[2]);
        this.populatePlaces(_pms[3]);
        this.populateGroupStudios(_pms[4]);
        this.populateJobTypes(_pms[5]);
        this.populateTeachers(_pms[6]);
        this.populateVisibilitiesLinks(_pms[7]);
      });
    }

    // popular appointment
    this.clearParans();
    this.populateAppointments();
  },
  computed: {
    calendarReadOnly() {
      return this.boContainerTeacher.is_active
    },
    selectedVerticalSlug() {
      if (this.calendarReadOnly) {
        const verticalNameHelper = {
          'carreiras-juridicas': 'carreiras',
          'medicina': 'med'
        }
        const verticalSlug = verticalNameHelper[this.boContainerTeacher.vertical] ?? this.boContainerTeacher.vertical
        return verticalSlug
      }
    },
    studios() {
      const studiosArray = []
      this.studioGroupSelected.forEach(group => {
        studiosArray.push(...group.studios)
      });
      return studiosArray
    },
    currentMonthOption: {
      get() {
        return parseInt(this.currentdate.currentmonth);
      },
      set(value) {
        let date = this.$refs.fullCalendar.getApi().getDate()
        date.setMonth(value - 1)
        this.gotoDate(new Date(date))
      }
    },
    currentYearOption: {
      get() {
        return parseInt(this.currentdate.currentyear);
      },
      set(value) {
        let date = this.$refs.fullCalendar.getApi().getDate()
        date.setFullYear(value)
        this.gotoDate(new Date(date))
      }
    },
    containsFilter() {
      if (
        this.companieSelected.some((s) => s.value == "-1") &&
        this.projectSelected.some((s) => s.value == "-1") &&
        this.studioGroupSelected.some((s) => s.value == "-1") &&
        this.studioSelected.some((s) => s.value == "-1") &&
        this.placesSelected.some((s) => s.value == "-1") &&
        this.transmissionsSelected.some((s) => s.value == "-1") &&
        this.jobTypesSelected.some((s) => s.value == "-1") &&
        this.teachersSelected.some((s) => s.value == "-1") &&
        this.operatorsSelected.value == "-1" &&
        this.canceledsSelected.value == "-1" &&
        this.youtubeLinksSelected.value == "-1" &&
        this.privateOublicSelected.value == "-1" &&
        !this.onlyHomeoffice &&
        !this.includeStudiosSupport
      ) {
        return false;
      }
      return true;
    },
  },
  watch: {
    studioGroupSelected() {
      this.updateSelectedStudiosDueToStudiosGroup()
    }
  },
  methods: {
    setTeacherParams() {
      if (this.$route.params.vertical) {
        const userData = JSON.parse(localStorage.getItem("userData"));
        this.boContainerTeacher = {
          is_active: true,
          teacher_id: Number(userData.id),
          vertical: this.$route.params.vertical ?? 'concursos',
        }
      }
    },
    updateSelectedStudiosDueToStudiosGroup() {
      let studios = this.studioSelected.filter(s => this.studios.find(v => v.value === s.value))
      if (studios.length === 0) {
        studios = [{ label: "Todos", value: "-1" }];
      }
      this.studioSelected = studios
    },
    gotoDate(date) {
      this.$refs.fullCalendar.getApi().gotoDate(date);
      this.setCurrentDate(this.$refs.fullCalendar.getApi().getDate());
      this.showAppointments();
    },
    changeCurrentDate() {
      if (
        this.$refs.fullCalendar.getApi().getDate().getMonth() + 1 !=
        this.currentdate.currentmonth
      ) {
        this.setCurrentDate(this.$refs.fullCalendar.getApi().getDate());
        this.showAppointments();
      }
    },
    onclickExibirCancelados() {
      this.showInactive = !this.showInactive;
      this.currentdate.active = this.showInactive;
      this.populateAppointments();
    },
    onClickFilter() {
      this.clearParans();

      if (this.canceledsSelected && this.canceledsSelected.value == "1") {
        // buscar cancelados 3 horas
        this.currentdate.is_canceled_out_of_time = true;
        this.currentdate.active = null;
        this.populateAppointments();
      } else if (
        this.calendarOptions.events.some((s) => s.is_canceled_out_of_time) ||
        this.calendarOptions.events.length <= 0
      ) {
        this.populateAppointments(); // popular novamente
      } else {
        this.filter();
      }
    },
    outClick() {
      if (this.isActiveDetails) return;
      this.showMoreLinks = false;
    },
    // reschedule(id) {
    //   if (this.$utils.isPermited("permission.appointment.edit")) {
    //     this.$router.push({
    //       path: `/pedagogic/appointment-new/${id}`,
    //     });
    //   }
    // },
    reschedule(id) {
      if (this.rescheduleIsEnable()) {
        this.$router.push({
          path: `/pedagogic/appointment/${id}`,
        });
      }
    },
    rescheduleIsEnable() {
      return (
        this.$utils.isPermited('permission.appointment.edit') &&
        this.appointmentid
      )
    },
    // rescheduleBetaIsEnable() {
    //   return (
    //     this.$utils.isPermited('permission.appointment.edit') &&
    //     // this.$utils.isPermited('permission.appointment.reschedule_new') &&
    //     this.appointmentid
    //   )
    // },
    clearParans() {
      this.currentdate.is_canceled_out_of_time = null;
      this.currentdate.active = this.showInactive;
    },

    showAppointments() {
      this.calendarOptions.events = [];
      this.onClickFilter();
    },
    showCompanies(res) {
      if (res && res.content) {
        this.companies_op = new Object();

        res.content.forEach((m) => {
          this.companies_op[m.id] = {
            value: m.id,
            label: m.name,
            color: m.color,
          };
        });

        this.companies = res.content.map((m) => {
          return {
            value: m.id,
            label: m.name,
            color: m.color,
          };
        });
      }
    },
    transmissionsTypes(res) {
      res.content.forEach((element) => {
        this.transmissions_types.push({
          label: element.name,
          value: element.id,
        });
      });
    },
    populateProjects(res) {
      if (res && res.content) {
        this.projects_op = new Object();

        res.content.forEach((m) => {
          this.projects_op[m.id] = { value: m.id, label: m.name };
        });

        this.projects = res.content.map((m) => {
          return {
            value: m.id,
            label: m.name,
          };
        });
      }
    },
    populatePlaces(res) {
      if (res && res.content) {
        this.places = res.content.map((m) => {
          return {
            label: m.name,
            value: m.id,
          };
        });
      }
    },
    populateGroupStudios(res) {
      if (res && res.content) {
        this.studiosGroup = res.content.map((m) => {
          return {
            label: `${m.name} - ${this.places.filter((f) => f.value == m.place_id)[0].label
              }`,
            place_id: m.place_id,
            value: m.id,
            studios: m.studios.map(s => ({
              label: s.name,
              value: s.id
            })),
          };
        });
      }
    },
    populateJobTypes(res) {
      if (res && res.content) {
        this.jobTypes = res.content.map((m) => {
          return {
            label: m.name,
            value: m.id,
          };
        });
      }
    },

    populateTeachers(res) {
      if (res && res.content) {
        this.teachers_op = new Object();

        res.content.forEach((m) => {
          this.teachers_op[m.id] = { value: m.id, label: m.name };
        });

        this.teachers = res.content.map((m) => {
          return {
            label: m.name,
            value: m.id,
          };
        });
      }
    },

    populateVisibilitiesLinks(res) {
      if (res && res.content) {
        this.privatesPublics = res.content.map((m) => {
          return {
            value: m.value,
            label: m.name,
          };
        });
        this.privatesPublics.unshift({ label: "Todos", value: "-1" });
      }
    },

    populateAppointments() {
      this.isloading = true;
      let params = {
        ...this.currentdate
      }
      if (this.selectedVerticalSlug) {
        params = {
          ...params,
          teacher_id: this.boContainerTeacher.teacher_id,
          company_name_slug: this.selectedVerticalSlug
        }
      }
      _appointmentService
        .showRange(params)
        .then((res) => {
          // popular agenda
          this.appointments = res.content;
          this.populateEvents(res.content);
        })
        .then(() => {
          this.filter();
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.isloading = false));
    },

    onClickCalendar(_item) {
      if (this.isActiveDetails) {
        this.isActiveDetails = false;
        setTimeout(() => {
          this.onClickCalendar(_item);
        }, "300");
      } else {
        const { id } = _item.event;

        this.isloading = true;
        _appointmentService
          .find_short(id, this.boContainerTeacher.teacher_id)
          .then((res) => {
            this.appointmentid = res.content;
            this.appointmentid.id = id;
            this.isActiveDetails = true;
          })
          .catch((error) => this.$utils.toastError("Notificação", error))
          .finally(() => (this.isloading = false));
      }
    },
    onClickByCode(id = 0) {
      let aap = id;

      if (id <= 0) {
        aap = this.filterbycode;
      }

      this.isloading = true;
      _appointmentService
        .find_short(aap, this.boContainerTeacher.teacher_id)
        .then((res) => {
          this.appointmentid = res.content;
          this.appointmentid.id = aap;
          this.isActiveDetails = true;
          this.isActiveFilter = false;
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.isloading = false));
    },
    formatDate(date) {
      let format =
        date.getFullYear() +
        "-" +
        this.addZero(date.getMonth() + 1) +
        "-" +
        this.addZero(date.getDate());
      return format;
    },
    addZero(num) {
      return num <= 9 ? "0" + num : num;
    },
    setCurrentDate(date = new Date()) {
      let currentmonth = this.addZero(date.getMonth() + 1);
      let currentyear = date.getFullYear();

      const _start =
        this.formatDate(date).split("-")[0] +
        "-" +
        this.formatDate(date).split("-")[1] +
        "-01";

      // definir data final do mes
      date.setMonth(date.getMonth() + 1);

      // subtrair um dia do mes seguinte para obter o ultimo dia do mes passado
      date.setDate(date.getDate() - 1);

      const _end =
        this.formatDate(date).split("-")[0] +
        "-" +
        this.formatDate(date).split("-")[1] +
        "-" +
        date.getDate();

      this.currentdate = {
        start: _start,
        end: _end,
        currentmonth,
        currentyear,
      };
    },
    changefilter(input) {
      if (input.length == 0) {
        input.push({ label: "Todos", value: "-1" });
      } else if (input.some((s) => s.value == "-1")) {
        input.shift();
      }
    },
    filter() {
      var companyid = this.companieSelected.map((m) => m.value);
      var projectid = this.projectSelected.map((m) => m.value);
      var groupstudioid = this.studioGroupSelected.map((m) => m.value);
      var studioid = this.studioSelected.map((m) => m.value);
      var includeStudiosSupport = this.includeStudiosSupport;
      var placesid = this.placesSelected.map((m) => m.value);
      var jobtypeid = this.jobTypesSelected.map((m) => m.value);
      var teachersid = this.teachersSelected.map((m) => m.value);
      var transmissionid = this.transmissionsSelected.map((m) => m.value);
      var visibilities = this.privateOublicSelected.value;
      var homeoffice = this.onlyHomeoffice ? true : "-1";

      var canceled = "-1";
      if (this.canceledsSelected) {
        if (this.canceledsSelected.value === "1") canceled = true;
        if (this.canceledsSelected.value === "2") canceled = false;
      }

      var youtube = "-1";
      if (this.youtubeLinksSelected) {
        if (this.youtubeLinksSelected.value === "1") youtube = true;
        if (this.youtubeLinksSelected.value === "2") youtube = false;
      }

      var pendencies = "-1";
      if (this.hasPendencySelected) {
        if (this.hasPendencySelected.value === "true") pendencies = true;
        if (this.hasPendencySelected.value === "false") pendencies = false;
      }

      var recordingPending = "-1";
      if (this.hasPendencySelected) {
        if (this.hasRecordingMediaPendingSelected.value === "true")
          recordingPending = true;
        if (this.hasRecordingMediaPendingSelected.value === "false")
          recordingPending = false;
      }

      const operatorid = this.$utils.getValueSelected(this.operatorsSelected);

      var _events = this.getFilterEvents(
        companyid,
        projectid,
        groupstudioid,
        includeStudiosSupport,
        operatorid,
        transmissionid,
        homeoffice,
        canceled,
        youtube,
        jobtypeid,
        teachersid,
        placesid,
        visibilities,
        pendencies,
        recordingPending,
        studioid
      );
      this.populateEvents(_events);
      this.isActiveFilter = false;
    },
    getFilterEvents(
      companyid,
      projectid,
      groupstudioid,
      includeStudiosSupport,
      operatorid,
      transmissionid,
      homeoffice,
      canceled,
      youtube,
      jobtypeid,
      teachersid,
      placesid,
      visibilities,
      pendencies,
      recordingPending,
      studioid
    ) {
      const groupPlacesids = this.studiosGroup
        .filter((f) => placesid.includes(f.place_id))
        .map((m) => m.value);
      return this.appointments.filter(
        (f) =>
          (visibilities === "-1" ||
            (f.links_visibilities &&
              f.links_visibilities.some(
                (ss) => ss.toString() === visibilities.toString()
              ))) &&
          (pendencies === "-1" || f.has_pendency === pendencies) &&
          (recordingPending === "-1" ||
            f.has_recording_media === recordingPending) &&
          (youtube === f.is_link || youtube === "-1") &&
          (canceled === f.is_canceled_out_of_time || canceled === "-1") &&
          (homeoffice === f.is_home_office || homeoffice === "-1") &&
          this.isOperator(f, operatorid) &&
          (projectid.some((s) => s.toString() === "-1") ||
            projectid.some((s) => s.toString() === f.project_id.toString())) &&
          (companyid.some((s) => s.toString() === "-1") ||
            companyid.some((s) => s.toString() === f.company_id.toString()) ||
            companyid.some(
              (s) =>
                f.youtube_companies &&
                f.youtube_companies.some((ss) => ss.toString() === s.toString())
            )) &&
          (transmissionid.some((s) => s.toString() === "-1") ||
            transmissionid.some(
              (s) =>
                f.transmissiontype &&
                f.transmissiontype.some((ss) => ss.toString() === s.toString())
            )) &&
          // filtro por grupo de estúdios
          (groupstudioid.some((s) => s.toString() === "-1") ||
            (includeStudiosSupport === false &&
              groupstudioid.some((s) =>
                f.studiosGroup.some((ss) => ss.toString() === s.toString())
              )) || // filtro por estudios de apoio
            (includeStudiosSupport &&
              groupstudioid.some((s) =>
                (f.studios_group_apoio
                  ? f.studiosGroup.concat(f.studios_group_apoio)
                  : f.studiosGroup
                ).some((ss) => ss.toString() === s.toString())
              ))) &&
          // filtro por estúdios
          (studioid.some((s) => s.toString() === "-1") ||
            (includeStudiosSupport &&
              studioid.some((s) =>
                (f.studios_apoio
                  ? f.studios.concat(f.studios_apoio)
                  : f.studios
                ).some((ss) => ss.toString() === s.toString())
              )) ||
            (includeStudiosSupport === false && studioid.some(s => f.studios.some((ss) => ss.toString() === s.toString())))) &&
          // buscar places
          (placesid[0] == "-1" ||
            groupPlacesids.some((s) =>
              f.studiosGroup.some((ss) => ss.toString() === s.toString())
              || f.studios_group_apoio?.some(sss => sss.toString() === s.toString())
            )) &&
          (teachersid.some((s) => s.toString() === "-1") ||
            teachersid.some((s) =>
              f.teachers.some((ss) => ss.toString() === s.toString())
            )) &&
          (jobtypeid.some((s) => s.toString() === "-1") ||
            jobtypeid.some((s) => s.toString() === f.job_type_id.toString()))
      );
    },
    isOperator(_appointment, _operatorid) {
      if (!_operatorid) {
        _operatorid = "-1";
      }

      if (_operatorid === "-1") {
        return true;
      } else {
        switch (_operatorid) {
          case "1":
            // sem operadores
            return _appointment.is_operators === false;
          case "2":
            // com operadores
            return _appointment.is_operators === true;
          default:
            return false;
        }
      }
    },
    populateEvents(_events) {
      const _appoints = _events.map((m) => {
        m.start_preparation += ":00";
        m.time += ":00";
        m.end_time += ":00";
        m.end_preparation += ":00";
        m.is_link =
          m.links_visibilities && m.links_visibilities.length > 0
            ? true
            : false;
        m.is_canceled_out_of_time = !!m.is_canceled_out_of_time;

        if (m.company_color) {
          m.color = m.company_color;
        } else {
          m.color = "#a1abb8"
        }

        if (m.project_name) {
          m.project = m.project_name
        } else {
          m.project = "--"
        }

        if (!this.calendarReadOnly) {
          if (m.teachers && m.teachers.length > 0) {
            if (!m.is_teacher && m.type === 2) {
              if (this.teachers_op[m.teachers[0]] && this.teachers_op[m.teachers[0]].label) {
                m.title += " - (" + this.teachers_op[m.teachers[0]].label + ")";
                m.is_teacher = true;
              }
            }
          }
        }

        return {
          groupstudios: m.studiosGroup,
          studios: m.studios,
          conflited_ids: m.conflited_ids,
          conflited: m.conflited,
          title: `${m.time.substring(0, 5)} ${m.project} - ${m.title} ${m.discipline_name ? ' - ' + m.discipline_name : ''}`,
          allDay: false,
          start: `${m.start} ${m.start_preparation
              ? m.start_preparation.substring(0, 5)
              : m.time.substring(0, 5)
            }`,
          end: `${m.end} ${m.end_time.substring(0, 5)}`,
          totalTime: this.minutesDifference(
            m.start + "T" + m.start_preparation,
            m.end + "T" + m.end_time
          ),
          preparationTime: this.minutesDifference(
            m.start + "T" + m.start_preparation,
            m.start + "T" + m.end_preparation
          ),
          color: m.color,
          id: m.id,
          className: [
            m.is_canceled_out_of_time || !m.active ? "status_canceled" : "",
            "event-border",
          ],
          start_preparation: m.start_preparation
            ? m.start_preparation.substring(0, 5)
            : null,
          end_preparation: m.end_preparation
            ? m.end_preparation.substring(0, 5)
            : null,
          relevance: m.relevance,
          is_canceled_out_of_time: m.is_canceled_out_of_time          
        };
      });
      this.calendarOptions.events = _appoints;
    },
    minutesDifference(start, end) {
      let inicio = new Date(start);
      let fim = new Date(end);
      let diferenca = new Date(fim - inicio);

      let result = diferenca.getUTCHours() * 60;
      result += diferenca.getUTCMinutes() * 1;

      return result;
    },
    getHeightPercent(totalTime, preparationTime) {
      let totalPercentage = 100;
      let partialPercentage = (totalPercentage * preparationTime) / totalTime;

      return `${partialPercentage}%`;
    },
    clearfilter() {
      this.companieSelected = [{ label: "Todos", value: "-1" }];
      this.transmissionsSelected = [{ label: "Todos", value: "-1" }];
      this.projectSelected = [{ label: "Todos", value: "-1" }];
      this.studioGroupSelected = [{ label: "Todos", value: "-1" }];
      this.studioSelected = [{ label: "Todos", value: "-1" }];
      this.placesSelected = [{ label: "Todos", value: "-1" }];
      this.operatorsSelected = { label: "Todos", value: "-1" };
      this.jobTypesSelected = [{ label: "Todos", value: "-1" }];
      this.teachersSelected = [{ label: "Todos", value: "-1" }];
      this.canceledsSelected = { label: "Todos", value: "-1" };
      this.youtubeLinksSelected = { label: "Todos", value: "-1" };
      this.privateOublicSelected = { label: "Todos", value: "-1" };
      this.onlyHomeoffice = false;
      this.includeStudiosSupport = false;

      this.filter();
    },
  },
  filters: {
    dateFormat: function (value) {
      if (value) {
        let datetime = value.split(" ");
        let date = datetime[0].split("-").reverse().join("/");
        let result = date + " (" + datetime[1] + "h)";
        return result;
      } else {
        return "--";
      }
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";

.fc .fc-day-today {
  background-color: rgb(255 247 208) !important;
}

.fc-list-event-time {
  display: none !important;
}

.btn-filter-appoitment {
  position: fixed;
  bottom: 5%;
  right: 45px;
  z-index: 99;
}

.fc-daygrid-more-link {
  font-weight: bolder !important;
}

.fc-popover-title {
  font-weight: bolder !important;
}

#sidebar-details-appointment {
  width: 65rem;
  margin: 15px !important;
  border-radius: 15px !important;
  height: 97vh !important;
}

#sidebar-details-appointment .b-sidebar-body {
  border-radius: 15px;
}

#full-calendar-appoitment-list .fc-view-harness-active .fc-popover {
  visibility: hidden;
}

.status_canceled {
  text-decoration: line-through !important;
}

.event-border {
  border-radius: 0px !important;
}

.fc-event-time {
  display: none;
}

.selector-button {
  position: absolute;
  left: 400px;
}

@media screen and (max-width: 70rem) {
  .selector-button {
    display: none;
  }
}
</style>