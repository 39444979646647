<template>
  <b-overlay :variant="skin" :show="isloading" spinner-variant="primary" spinner-type="grow">
    <b-form class="px-1" v-if="appointment">
      <!-- CANCELAR AGENDAMENTO -->
      <b-modal id="modal-cancelar-no-prazo" cancel-variant="outline-secondary" ok-title="Cancelar Agendamento"
        cancel-title="Fechar" centered :title="`Deseja Cancelar Agendamento #${this.id} ?`" ok-variant="danger"
        @ok="onClickCancel(false)">
        <b-form>
          <b-form-group label="Motivo *">
            <v-select v-model="reasonSelected" :options="reason" autocomplete="off" :clearable="false" />
          </b-form-group>
          <b-form-group label="Descrição (Opcional)">
            <b-form-textarea rows="7" v-model="reason_desc" placeholder="Informe uma breve descrição (opcional)" />
          </b-form-group>
        </b-form>
      </b-modal>

      <!-- CANCELAR AGENDAMENTO CHILDS -->
      <b-modal v-model="showCancelChilds" id="modal-cancelar-childs" cancel-variant="outline-secondary"
        ok-title="Quero cancelar!" cancel-title="Fechar" centered :title="`Cancelamento do Agendamento #${this.id}`"
        @ok="onClickCancel(true)" v-if="appointment_childs && appointment_childs.length > 0">
        <div class="row">
          <div class="col-12">
            <h3>(Gravações OFF)</h3>
            <strong>Ao confirmar será cancelado também os agendamentos filhos
              abaixo.</strong>
            <ul>
              <li v-for="ch in appointment_childs" :key="ch">{{ ch }}</li>
            </ul>
          </div>
        </div>
      </b-modal>

      <b-alert v-if="!appointment.active" class="mt-1" show variant="danger">
        <div class="alert-body">
          <feather-icon class="mr-25" icon="AlertTriangleIcon" />
          <span class="ml-25"> Agendamento cancelado </span>
        </div>
      </b-alert>
      <b-tabs class="mt-75" pills>
        <b-tab active title="Dados Básicos" class="pl-1 pr-1">
          <b-alert v-if="appointment.active && !appointment.sync_calendar" class="mt-1" show variant="warning">
            <div class="alert-body">
              <feather-icon class="mr-25" icon="AlertTriangleIcon" />
              <span class="ml-25">
                O agendamento ainda não está sincronizado com o Google Calendar,
                isso pode levar alguns minutos
              </span>
            </div>
          </b-alert>

          <!-- CANCELADO DENTRO DO PRAZO 3 HORAS (CONFIGURAÇÃO) -->
          <b-alert v-if="appointment.canceled_out_of_time" variant="danger" class="mt-1" show>
            <div class="alert-header" v-show="appointment.canceled_date">
              Cancelado fora do horário permitido
            </div>

            <div class="alert-body">
              <b v-show="appointment.canceled_date">Cancelado em: {{ appointment.canceled_date }} <br />
              </b>

              <span v-if="appointment.name_canceled_reason_out_of_time ||
                appointment.description_canceled_reason_out_of_time
                ">
                <b>Motivo do Cancelamento:</b>
                {{ appointment.name_canceled_reason_out_of_time }} <br />
                {{ appointment.description_canceled_reason_out_of_time }}
              </span>
            </div>
          </b-alert>

          <!-- MOTIVO DE CANCELAMENTO DEFAULT -->
          <b-alert v-if="appointment.canceled_reason_id > 0" variant="danger" class="mt-1" show>
            <div class="alert-heading">
              <span v-if="appointment.name_canceled_reason &&
                appointment.description_canceled_reason
                ">
                {{ appointment.name_canceled_reason }}
              </span>
              <span v-else> Motivo do Cancelamento </span>
            </div>

            <div class="alert-body">
              <span v-if="appointment.description_canceled_reason">
                <b>Motivo do Cancelamento:</b>
                {{ appointment.description_canceled_reason }}
              </span>
              <span v-else>
                {{ appointment.name_canceled_reason }}
              </span>
            </div>
          </b-alert>

          <b-row class="pt-1 box-appointment-details" :class="`box-appointment-details-bg-${skin}`">
            <b-col md="6">
              <b-form-group label="Data do Evento" class="font-weight-bolder">
                <strong>
                  {{ appointment.start_date }} - {{ appointment.start_time }}h
                  até {{ appointment.end_time }}h
                </strong>
              </b-form-group>
            </b-col>
            <b-col md="6" v-if="appointment.start_preparation_time !== appointment.start_time
              ">
              <b-form-group label="Início de Preparação" class="font-weight-bolder">
                <strong> {{ appointment.start_preparation_time }}h </strong>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row :class="`pl-50 pt-1 box-appointment-details-bg-${skin}`"
            style="border-top-right-radius: 15px; border-top-left-radius: 15px">
            <b-col :md="appointment.title && appointment.title.length <= 30 ? '6' : '12'
              ">
              <b-form-group :label="appointment.ds_type" class="font-weight-bolder">
                <span class="font-weight-light">
                  <div v-if="appointment.ds_type === 'Aula'">
                    <a :href="`/pedagogic/project-view/${appointment.project_id}/course/${appointment.course_id}`"
                      target="_blank" v-if="$utils.isPermited('permission.course.lesson.edit') && !calendarReadOnly"
                      style="text-decoration: revert !important">
                      {{ appointment.title }}
                    </a>
                    <span v-else>
                      {{ appointment.title }}
                    </span>
                  </div>
                  <a :href="`/pedagogic/project-view/${appointment.project_id}/event/${appointment.event_id}`"
                    v-else-if="$utils.isPermited('permission.events.edit') && !calendarReadOnly"
                    style="text-decoration: revert !important" target="_blank">
                    {{ appointment.title }}
                  </a>
                  <span v-else>
                    {{ appointment.title }}
                  </span>
                </span>
              </b-form-group>
            </b-col>
            <b-col :md="appointment.project_name &&
                appointment.project_name.length <= 30
                ? '6'
                : '12'
              " :class="appointment.project_name &&
      appointment.project_name.length <= 30
      ? 'pt-1'
      : ''
    ">
              <b-form-group label="Projeto" class="font-weight-bolder">
                <span class="font-weight-light">
                  <a :href="`/pedagogic/project-view/${appointment.project_id}`"
                    style="text-decoration: revert !important" target="_blank"
                    v-if="$utils.isPermited('permission.project.edit') && !calendarReadOnly">
                    {{ appointment.project_name }}
                  </a>
                  <span v-else>
                    {{ appointment.project_name }}
                  </span>
                </span>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row :class="`pl-50 box-appointment-details-bg-${skin}`" style="
              border-bottom-right-radius: 15px;
              border-bottom-left-radius: 15px;
              margin-bottom: 25px;
            ">
            <b-col>
              <b-form-group label="Empresa" class="font-weight-bolder">
                <b-badge style="background: #6610f2">
                  {{ appointment.company_name }}
                </b-badge>
              </b-form-group>
            </b-col>
            <b-col v-if="appointment.jobtype_name">
              <b-form-group label="Tipo" class="font-weight-bolder">
                <b-badge variant="info">
                  {{ appointment.jobtype_name }}
                </b-badge>
              </b-form-group>
            </b-col>
            <b-col v-if="appointment.is_recurrent">
              <b-badge variant="warning" class="font-weight-bolder" style="margin-top: 28px;">
                Recorrente
              </b-badge>
            </b-col>
            <b-col v-if="appointment.products && appointment.products.length > 0">
              <b-form-group label="Produtos" class="font-weight-bolder">
                <b-badge variant="success" class="mr-25 mb-25" v-for="(prd, iprd) in appointment.products"
                  :key="`appointment-product-${iprd}`">
                  {{ prd }}
                </b-badge>
              </b-form-group>
            </b-col>
            <b-col v-if="appointment.discipline_name || appointment.classification_name
              ">
              <b-form-group label="Disciplina" class="font-weight-bolder">
                <b-badge style="background: #ffc107">
                  {{ getDisciplineName(appointment) }}
                </b-badge>
              </b-form-group>
            </b-col>
            <b-col  v-if="appointment.classification_name || classifications.length > 0
              ">
              <b-form-group label="Classificação" class="font-weight-bolder">
                <b-badge style="background: #ffc107" class="mr-25 mb-25"  v-if="appointment.classification_name">
                  {{ getClassificationPlatformName(appointment) }}
                </b-badge>
                <b-badge style="background: #ffc107" class="mr-25 mb-25" v-for="(classification, iclass) in classifications"
                  :key="`appointment-classification-${iclass}`">
                  {{ classification.name }}
                </b-badge>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="box-appointment-details" :class="`box-appointment-details-bg-${skin}`">
            <b-col class="pt-1">
              <b-form-group class="font-weight-bolder" label="Google Calendar">
                <template slot="label">
                  Google Calendar
                  <a class="mb-1" style="color: #f5912c" :id="`event-calendar-url-${appointment.id}`"
                    @click="openPublicEventUrl(appointment.google_event_url)">
                    <feather-icon icon="Share2Icon" size="16" />
                    <b-tooltip triggers="hover" :target="`event-calendar-url-${appointment.id}`" title="URL"
                      :delay="{ show: 100, hide: 50 }" />
                  </a>
                </template>
                <div class="form-label-group">
                  <b-form-input v-model="title_calendar" :disabled="!alterTitleCalendar || calendarReadOnly"
                    autocomplete="off" />
                </div>
              </b-form-group>
            </b-col>
            <b-col v-if="alterTitleCalendar && !calendarReadOnly" cols="auto" style="margin-top: 41px;">
              <b-button @click="saveTitleGooleCalendar()" class="btn-icon" variant="info">
                <feather-icon icon="SaveIcon" />
              </b-button>
            </b-col>
          </b-row>

          <b-row class="box-appointment-details" :class="`box-appointment-details-bg-${skin}`" v-if="appointment.participants && appointment.participants.length > 0
            ">
            <b-col cols="12" class="mt-1">
              <b-form-group label="Participantes" class="font-weight-bolder">
                <b-row>
                  <b-col v-for="(par, index) in appointment.participants" :key="`appointment-participant-${index}`"
                    class="col-4 d-flex mb-1">
                    <b-avatar variant="secondary" class="mr-25 mb-25" badge-top badge-offset="-5px" badge-variant="light"
                      :src="par.avatar">
                      <template #badge>
                        <feather-icon :icon="getIconParticipant(par)" />
                      </template>
                    </b-avatar>
                    <div class="ml-25" style="display: grid">
                      <strong>{{ par.name }}
                        <feather-icon id="icon-mobile" v-if="par.mobile" icon="SmartphoneIcon" size="16" variant="info" />
                        <feather-icon id="icon-phoned" v-if="par.phone" icon="PhoneIcon" size="16" variant="info"
                          class="ml-25" />

                        <b-tooltip v-if="par.mobile" :title="`Celular: ${par.mobile}`" target="icon-mobile" />
                        <b-tooltip v-if="par.phone" :title="`Tel: ${par.phone}`" target="icon-phoned" />
                      </strong>
                      <small v-if="par.email" class="text-muted text-truncate">{{ par.email }}</small>
                    </div>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="box-appointment-details" :class="`box-appointment-details-bg-${skin}`" v-if="(appointment.childs && appointment.childs.length > 0) ||
            (appointment.parents && appointment.parents.length > 0)
            ">
            <!-- PARENTS/FILHOS -->
            <b-col cols="12" class="mt-75" v-if="appointment.childs && appointment.childs.length > 0">
              <b-form-group label="Agendamentos Filhos" class="font-weight-bolder">
                <b-badge v-for="(ch, ich) in appointment.childs" :key="`appointment-child-${ich}`"
                  class="mr-25 cursor-pointer" @click="goAppointment(ch)">
                  #{{ ch }}
                </b-badge>
              </b-form-group>
            </b-col>

            <b-col cols="12" class="mt-75" v-if="appointment.parents && appointment.parents.length > 0">
              <b-form-group label="Agendamento Pai" class="font-weight-bolder">
                <b-badge v-for="(pr, ipr) in appointment.parents" :key="ipr" class="mr-25 cursor-pointer"
                  variant="primary" @click="goAppointment(pr)">
                  #{{ pr }}
                </b-badge>
              </b-form-group>
            </b-col>
          </b-row>
        </b-tab>

        <!-- studios -->
        <b-tab title="Estúdio" class="pl-1 pr-1" @click="getShortStudios">
          <!-- Evento -->
          <b-overlay :variant="skin" :show="studioLoading" spinner-variant="primary" spinner-type="grow"
            style="min-height: 300px">
            <div v-if="eventStudios != null">
              <!-- Estúdio principal -->
              <b-row class="box-appointment-details" :class="`box-appointment-details-bg-${skin}`"
                style="display: block; align-items: baseline">
                <b-row>
                  <b-col class="align-center" style="display: block; align-items: baseline">
                    <b-form-group label="Evento" class="font-weight-lighter">
                      <p class="font-weight-bolder">
                        {{ eventStudios[0].event_name }}
                      </p>
                    </b-form-group>
                  </b-col>
                  <b-col v-if="displayQuestions">
                    <b-form-group label="Total de Questões" class="font-weight-lighter">
                      <p class="font-weight-bolder">
                        {{ questionsTotal.question_number }}
                      </p>
                    </b-form-group>
                  </b-col>
                  <b-col v-if="displayQuestions">
                    <b-form-group label="Total Questões Reaproveitadas" class="font-weight-lighter">
                      <p class="font-weight-bolder">
                        {{ questionsTotal.reused_question_number }}
                      </p>
                    </b-form-group>
                  </b-col>
                  <b-col cols="auto" v-for="recordingedia in eventStudios[0].recordingmedias" :key="recordingedia.id">
                    <b-button v-if="!$utils.isPermited(
                      'permission.audiovisual.recorgind.view'
                    )
                      " size="sm" variant="primary">
                      Gravação #{{ recordingedia.id }}
                    </b-button>
                    <b-button v-else size="sm" variant="warning" :to="{
                      name: 'audiovisual-recording-view',
                      params: { id: `${recordingedia.id}` },
                    }">Gravação #{{ recordingedia.id }}
                    </b-button>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-form-group label="Estúdio Principal" class="font-weight-lighter">
                      <p class="font-weight-bolder">
                        {{ eventStudios[0].studio_name }}
                      </p>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="3">
                    <b-form-group label="Professor Âncora" class="font-weight-lighter">
                      <p class="font-weight-bolder">
                        {{ eventStudios[0].teacher_res }}
                      </p>
                    </b-form-group>
                  </b-col>
                  <b-col cols="3">
                    <b-form-group label="Operador" class="font-weight-lighter">
                      <p class="font-weight-bolder">
                        {{ eventStudios[0].operator_name }}
                      </p>
                    </b-form-group>
                  </b-col>
                  <b-col cols="3" v-if="eventStudios[0].operator_backup_name">
                    <b-form-group label="Backup" class="font-weight-lighter">
                      <p class="font-weight-bolder">
                        {{ eventStudios[0].operator_backup_name }}
                      </p>
                    </b-form-group>
                  </b-col>
                  <b-col cols="3"
                    v-if="eventStudios[0].appointment_studios_moderator && eventStudios[0].appointment_studios_moderator.length > 0">
                    <b-form-group label="Moderadores" class="font-weight-lighter">
                      <p v-for="asm in eventStudios[0].appointment_studios_moderator" :key="asm.id"
                        class="font-weight-bolder">
                        {{ getNameModerator(asm.moderator) }}
                      </p>
                    </b-form-group>
                  </b-col>
                </b-row>
                <div class="d-inline-block">
                  <b-badge v-if="eventStudios[0].accept_recording" variant="danger" class="mr-1">
                    Gravado
                  </b-badge>
                  <b-badge v-if="eventStudios[0].recording_all_events" variant="danger" class="mr-1">
                    Todo Gravado
                  </b-badge>
                  <b-badge v-if="eventStudios[0].is_home_office" class="mr-1" variant="info">
                    Home office
                  </b-badge>
                  <b-badge v-if="eventStudios[0].is_streaming" class="mr-1">
                    Somente Transmissão
                  </b-badge>
                </div>
              </b-row>
              <!-- SubEventos -->
              <b-row v-for="(subevent, i) in eventStudios[0].subevents" :key="`studio-subevent-${i}`"
                class="box-appointment-details pt-1" :class="`box-appointment-details-bg-${skin}`"
                style="display: block; align-items: baseline">
                <b-row>
                  <b-col>
                    <p class="font-weight-bolder mt-50">
                      {{ subevent.name }} -
                      {{ subevent.start_date.split("T")[1].substring(0, 5) }} às
                      {{ subevent.finish_date.split("T")[1].substring(0, 5) }}
                    </p>
                  </b-col>
                  <b-col v-if="subevent.question_number > 0">
                    <p class="font-weight-bolder mt-50">
                      Número de Questões: {{ subevent.question_number }}
                    </p>
                  </b-col>
                  <b-col v-if="subevent.question_number > 0">
                    <p class="font-weight-bolder mt-50">
                      Questões Reaproveitadas: {{ subevent.reused_question_number }}
                    </p>
                  </b-col>
                  <b-col cols="auto" v-if="!calendarReadOnly">
                    <b-dropdown v-for="(
                        recordingmediapending, i
                      ) in subevent.recordingMediaPendings" :key="i" split size="sm"
                      :text="`Pendência #${recordingmediapending.id}`" :variant="getDropDownVariant(
                        recordingmediapending.recordingMediaSubjectPendings[0]
                          .mediaSubjectVideo
                      )
                        " right :split-to="{
    name: 'audiovisual-recording-pending-view',
    params: { id: `${recordingmediapending.id}` },
  }">
                      <b-dropdown-item v-if="recordingmediapending.recordingMediaSubjectPendings[0]
                          .mediaSubjectVideo.length == 0
                        ">
                        Nenhum assunto associado
                      </b-dropdown-item>
                      <b-dropdown-item v-for="recordingPeding in recordingmediapending
                        .recordingMediaSubjectPendings[0].mediaSubjectVideo"
                        :key="recordingPeding.recording_media_id" :to="{
                          name: 'audiovisual-recording-view',
                          params: {
                            id: `${recordingPeding.recording_media_id}`,
                            subject: `${recordingPeding.id}`,
                          },
                        }" style="max-width: 400px">
                        <span :style="{
                          color:
                            recordingPeding.status_edition_recording_media
                              ? 'green'
                              : '',
                        }">
                          #{{ recordingPeding.id }} -
                          {{ recordingPeding.subject }}
                        </span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </b-col>
                </b-row>
                <b-row v-for="(studio, i) in subevent.studios" :key="`studio-${i}`">
                  <b-col cols="4" style="display: flex; align-items: baseline">
                    <b-form-group v-if="studio.main" label="Estúdio Principal" class="font-weight-lighter">
                      <p class="font-weight-bolder">{{ studio.name }}</p>
                    </b-form-group>
                    <b-form-group v-else label="Estúdio de Apoio" class="font-weight-lighter">
                      <p class="font-weight-bolder">{{ studio.name }}</p>
                    </b-form-group>
                  </b-col>
                  <b-col style="display: flex; align-items: baseline">
                    <b-form-group label="Professores" class="font-weight-lighter">
                      <div v-for="(teacher, i) in subevent.teachers" :key="`teacher-${i}`">
                        <p v-if="teacher.teacher_res" class="font-weight-bolder">
                          {{ teacher.teacher_name + " (Âncora)" }}
                        </p>
                        <p v-else class="font-weight-bolder">
                          {{ teacher.teacher_name }}
                        </p>
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col style="display: flex; align-items: baseline">
                    <b-form-group label="Operador" class="font-weight-lighter">
                      <p class="font-weight-bolder">
                        {{ studio.operator_name }}
                      </p>
                    </b-form-group>
                  </b-col>
                  <b-col style="display: flex; align-items: baseline">
                    <b-form-group label="Operador Backup" class="font-weight-lighter">
                      <p class="font-weight-bolder">
                        {{ studio.operator_backup_name }}
                      </p>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-row>
            </div>
            <!-- Curso -->
            <div v-if="lessonStudio != null">
              <!-- Curso principal -->
              <b-row class="box-appointment-details pt-1 pb-1" :class="`pl-2 box-appointment-details-bg-${skin}`"
                style="display: block; align-items: baseline">
                <b-row>
                  <b-col class="align-center" style="display: block; align-items: baseline">
                    <b-form-group label="Aula" class="font-weight-lighter">
                      <!-- <p class="font-weight-bolder">{{lessonStudio[0].lesson_name}}</p><br/> -->
                      <p class="font-weight-bolder">
                        {{ lessonStudio[0].course_name }}
                      </p>
                    </b-form-group>
                  </b-col>
                  <b-col cols="auto" v-for="recordingedia in lessonStudio[0].recordingmedias" :key="recordingedia.id">
                    <b-button v-if="!$utils.isPermited(
                      'permission.audiovisual.recorgind.view'
                    )
                      " size="sm" variant="primary">
                      Gravação #{{ recordingedia.id }}
                    </b-button>
                    <b-button v-else size="sm" variant="warning" :to="{
                      name: 'audiovisual-recording-view',
                      params: { id: `${recordingedia.id}` },
                    }">Gravação #{{ recordingedia.id }}
                    </b-button>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-form-group label="Estúdio Principal" class="font-weight-lighter">
                      <p class="font-weight-bolder">
                        {{ lessonStudio[0].studio_name }}
                      </p>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="3">
                    <b-form-group label="Professor" class="font-weight-lighter">
                      <p class="font-weight-bolder" v-for="teacher in lessonStudio[0].courseteachers"
                        :key="teacher.teacher_id">
                        {{ teacher.teacher_name }}
                      </p>
                    </b-form-group>
                  </b-col>
                  <b-col cols="3">
                    <b-form-group label="Operador" class="font-weight-lighter">
                      <p class="font-weight-bolder">
                        {{ lessonStudio[0].operator_name }}
                      </p>
                    </b-form-group>
                  </b-col>
                  <b-col cols="3" v-if="lessonStudio[0].operator_backup_name">
                    <b-form-group label="Backup" class="font-weight-lighter">
                      <p class="font-weight-bolder">
                        {{ lessonStudio[0].operator_backup_name }}
                      </p>
                    </b-form-group>
                  </b-col>
                  <b-col cols="3"
                    v-if="lessonStudio[0].appointment_studios_moderator && lessonStudio[0].appointment_studios_moderator.length > 0">
                    <b-form-group label="Moderadores" class="font-weight-lighter">
                      <p v-for="asm in lessonStudio[0].appointment_studios_moderator" :key="asm.id"
                        class="font-weight-bolder">
                        {{ getNameModerator(asm.moderator) }}
                      </p>
                    </b-form-group>
                  </b-col>
                </b-row>
                <div class="d-inline-block">
                  <b-badge v-if="lessonStudio[0].accept_recording" variant="danger" class="mr-1">
                    Gravado
                  </b-badge>
                  <b-badge v-if="lessonStudio[0].recording_all_events" variant="danger" class="mr-1">
                    Todo Gravado
                  </b-badge>
                  <b-badge class="mr-1" variant="info" v-if="lessonStudio[0].is_home_office">
                    Home office
                  </b-badge>
                  <b-badge class="mr-1" v-if="lessonStudio[0].is_streaming">
                    Somente Transmissão
                  </b-badge>
                </div>
              </b-row>
            </div>
          </b-overlay>
        </b-tab>

        <b-tab class="pl-1 pr-1" title="Transmissões" @click="getShortLinks">
          <div class="d-flex justify-content-end align-items-center" :style="{ gap: '5px' }">
            <span>Limites Disponíveis:</span>
            <feather-icon size="20" icon="InfoIcon" id="tooltip-button-3" />
            <b-tooltip target="tooltip-button-3" placement="bottom" triggers="hover">
              <div :style="{ display: 'grid', gap: '8px' }">
                <div v-for="(transmissionLimit, i) in transmissionLimits" :key="`create-link-item-${i}`">
                  <span>
                    <b-badge variant="light-warning">
                      {{ transmissionLimit.label }}
                    </b-badge>
                  </span>
                  : {{ transmissionLimit.amount.toLocaleString('pt-BR', {
                    minimumIntegerDigits: 2,
                  }) }}
                </div>
              </div>
            </b-tooltip>
          </div>
          <b-row class="pt-1">
            <b-col cols="12">
              <div class="box-appointment-details p-1" :class="`box-appointment-details-bg-${skin}`"
                v-for="(ll, index) in links" :key="`box-appointment-${index}`">
                <b-row class="mb-1">
                  <b-col cols="6" v-if="isWriteYoutube">
                    <b-form-group :label="`${ll.jobTypeTransmissionSelected
                        ? ll.jobTypeTransmissionSelected.label
                        : ''
                      } (Link)`" class="font-weight-bolder">
                      <b-form-input placeholder="Informe a URL" autocomplete="off" v-model="ll.youtube_url" />
                    </b-form-group>
                  </b-col>
                  <b-col cols="6" v-if="isWriteYoutube">
                    <b-form-group label="Título" class="font-weight-bolder">
                      <b-form-input placeholder="Título do vídeo" autocomplete="off" v-model="ll.youtube_title"
                        @keyup="inputUrlTitle(ll.youtube_title, index)" />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" v-if="!isWriteYoutube">
                    <b-form-group v-if="ll.youtube_url" class="font-weight-bolder" label="Link">
                      <a class="font-weight-light" style="text-decoration: revert !important" :href="ll.youtube_url"
                        target="_blank">
                        {{ ll.youtube_url }}
                      </a>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="6">
                    <b-form-group label="Empresa" class="font-weight-bolder">
                      <v-select v-if="companies.length > 0" v-model="ll.companieSelected" :options="index === 0 && ll.companieSelected
                          ? companies.filter(
                            (f) => f.value === ll.companieSelected.value
                          )
                          : companies
                        " autocomplete="off" :clearable="false" />
                    </b-form-group>
                  </b-col>
                  <b-col cols="4">
                    <b-form-group label="Visibilidade Final" class="font-weight-bolder">
                      <v-select :options="aovivoOptions" v-model="ll.aovivo" autocomplete="off" :clearable="false" />
                    </b-form-group>
                  </b-col>

                  <b-col cols="2" style="margin-top: 27px; text-align: end" v-if="!calendarReadOnly">
                    <b-button @click="removeLink(ll)" v-if="isWriteYoutube" class="btn-icon mr-1" variant="danger">
                      <feather-icon icon="Trash2Icon" />
                    </b-button>

                    <b-button @click="saveNewLink(ll)" v-if="isWriteYoutube" class="btn-icon" variant="info">
                      <feather-icon icon="SaveIcon" />
                    </b-button>
                  </b-col>
                </b-row>
              </div>
            </b-col>

            <b-col cols="12" class="text-center">
              <strong v-if="!isloading && links && links.length <= 0" style="display: block; margin-bottom: 10px">Não
                existe transmissão cadastrada para esse evento</strong>
              <b-dropdown text="Nova Transmissão" variant="info" right v-if="!calendarReadOnly">
                <b-dropdown-item v-for="(_item, i) in jobTypeTransmissionList" @click="addNewLink(_item)"
                  :key="`transmission-list-${i}`">
                  {{ _item.label }}
                </b-dropdown-item>
              </b-dropdown>
            </b-col>
          </b-row>
        </b-tab>

        <b-tab @click="getChat" v-if="!calendarReadOnly">
          <template #title>
            <span class="mr-1">Avisos</span>
            <feather-icon v-if="chatData &&
              chatData.chat &&
              chatData.chat.chat &&
              chatData.chat.chat.length > 0
              " icon="BellIcon" size="20" />
          </template>
          <b-row>
            <b-col cols="12">
              <b-card class="chat-widget" no-body>
                <b-card-header>
                  <div class="d-flex align-items-center">
                    <h5 class="mb-0">Mensagens</h5>
                  </div>
                </b-card-header>
                <section class="chat-app-window">
                  <!-- User Chat Area -->
                  <vue-perfect-scrollbar ref="refChatLogPS" :settings="perfectScrollbarSettings"
                    class="user-chats scroll-area" style="min-height: 60%">
                    <chat-log :chat-data="chatData" />
                  </vue-perfect-scrollbar>
                </section>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="fixed-bottom m-25">
            <b-col cols="12" class="p-0 m-0">
              <div class="example">
                <table>
                  <tr>
                    <td>
                      <quill-editor class="editor" :options="editorOption" v-model="chatInputMessage">
                      </quill-editor>
                    </td>
                    <td style="width: 10px">
                      <b-button variant="primary" class="btn-icon" style="height: 20vh" @click="sendMessage">
                        <feather-icon icon="SendIcon" size="25" />
                      </b-button>
                    </td>
                  </tr>
                </table>
              </div>
            </b-col>
          </b-row>
        </b-tab>

        <!-- histórico -->
        <b-tab title="Histórico" @click="getHistories" v-if="!calendarReadOnly">
          <p class="mt-3 text-center" v-if="histories.length <= 0 && !isloading">
            Sem histórico registrado.
          </p>
          <app-timeline v-else class="m-2">
            <app-timeline-item v-for="(h, i) in histories" :key="`history-${i}`" :icon="getIcon(h)"
              :variant="getVariant(h)">
              <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                <h6>{{ h.action_name }}</h6>
                <small class="text-muted">{{ getdsDate(h.data) + " por " }}
                  <span style="text-transform: capitalize">{{
                    h.user
                  }}</span></small>
              </div>
              <b-media style="margin-top: 5px" v-if="h.action_code == 4">
                <template #aside>
                  <b-avatar />
                </template>
                <span style="display: block; margin-top: 5px">{{
                  h.description
                }}</span>
              </b-media>
              <p v-else-if="h.description">{{ h.description }}</p>
              <p v-else></p>
            </app-timeline-item>
          </app-timeline>
        </b-tab>
      </b-tabs>
    </b-form>
  </b-overlay>
</template>

<script>
import useAppConfig from "@core/app-config/useAppConfig";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import _appointmentStudioService from "@/services/appointment-studio-service";
import _appointmentService from "@/services/appointment-service";
import _companyService from "@/services/company-service";
import _reasonService from "@/services/reason-service";
import _appointmentLinkService from "@/services/appointment-link-service";
import _lessonService from "@/services/lessons-service";
import _eventService from "@/services/event-service";
import { format, parseISO } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";

import {
  BFormTags,
  BCard,
  BCardHeader,
  BAvatar,
  BForm,
  BFormInput,
  BInputGroup,
  BButton,
  BMedia,
  BAlert,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import ChatLog from "@/views/apps/chat/ChatLog.vue";

export default {
  setup() {
    const { skin } = useAppConfig();
    return {
      skin,
    };
  },
  props: {
    appointment: {
      type: Object,
      required: true,
    },
    calendarReadOnly: false,
  },
  components: {
    AppTimeline,
    AppTimelineItem,
    BFormTags,
    BCard,
    BCardHeader,
    BAvatar,
    BForm,
    BFormInput,
    BInputGroup,
    BButton,
    BMedia,
    BAlert,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    // SFC
    ChatLog,
    // 3rd party
    VuePerfectScrollbar,
  },
  data() {
    return {
      studioLoading: false,
      histories: [],
      loadingTransmission: false,
      isloading: false,
      loadingop: false,
      loadingopbk: false,
      isOnlyRead: false,
      isWriteYoutube: false,
      isEditTransmission: false,
      isDisableSaveYoutube: true,
      iswarningsaveYoutube: false,
      demandAudioVisual: false,
      validate: true,
      youtube_url: "",
      participants: [""],
      lessonStudio: null,
      eventStudios: null,
      questionsTotal: {
        question_number: 0,
        reused_question_number: 0,
      },
      displayQuestions: false,
      aovivoOptions: [
        { label: "Público", value: "0" },
        { label: "Privado", value: "1" },
        { label: "Não Listado", value: "2" },
      ],
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ font: [] }],
            [{ color: [] }],
          ],
        },
      },
      value: null,
      links: [],
      companies: [],
      companieSelected: null,
      jobTypeTransmissionList: [],
      perfectScrollbarSettings: {
        maxScrollbarLength: 150,
        wheelPropagation: false,
      },
      chatInputMessage: "",
      chatData: {
        chat: {
          id: 0,
          chat: [],
        },
        contact: {
          id: 0,
        },
      },
      reason: [],
      reasonSelected: null,
      reason_desc: "",
      aovivo: null,

      appointment_childs: [],
      showCancelChilds: false,
      id: 0,
      alterTitleCalendar: false,
      title_calendar: "",
      classifications: [],
    };
  },
  created() {
    this.demandAudioVisual = this.$utils.isPermited(
      "permission.appointment.audiovisual.create"
    );

    const userData = JSON.parse(localStorage.getItem("userData"));
    if (userData && userData.roles) {
      this.isEditTransmission = !userData.roles.some(
        (s) => s === "permission.appointment.edit"
      );

      this.isWriteYoutube = userData.roles.some(
        (s) =>
          s === "permission.backoffice.youtube.edit" ||
          s === "permission.backoffice.youtube.create" ||
          s === "admin"
      );
      this.alterTitleCalendar = this.$utils.isPermited(
        "permission.appointment.edit"
      );
    }

    this.init();
  },
  destroyed() {
    this.$destroy();
  },
  computed: {
    transmissionLimits() {
      return this.appointment.transmissions.map((m) => {
        return {
          amount: m.amount,
          label: m.name,
          value: m.id,
        };
      })
    },    
  },
  methods: {
    init() {
      this.isloading = true;
      const _promisses = [_companyService.showAll(), _reasonService.show(), _appointmentService.getClassifications(this.appointment.id)];

      Promise.all(_promisses)
        .then((_pms) => {
          this.populateCompanies(_pms[0]);
          this.populateReason(_pms[1]);
          this.populateAppointment();
          this.populateClassifications(_pms[2]);
          this.isloading = false;
        })
        .catch((error) => this.$utils.toastError("Notificação", error));
      //.finally(() => (this.isloading = false));
    },

    populateAppointment() {
      this.id = this.appointment.id;

      this.jobTypeTransmissionList = this.transmissionLimits;

      // CANCELAMENTO DENTRO DO PRAZO
      if (this.appointment.canceled_reason_out_of_time_id > 0) {
        if (
          this.reason.some(
            (s) => s.value == this.appointment.canceled_reason_out_of_time_id
          )
        ) {
          // reason
          this.appointment.name_canceled_reason_out_of_time =
            this.reason.filter(
              (s) => s.value == this.appointment.canceled_reason_out_of_time_id
            )[0].label;
        }
      }

      // CANCELAMENTO DEFAULT
      if (this.appointment.canceled_reason_id > 0) {
        if (
          this.reason.some(
            (s) => s.value == this.appointment.canceled_reason_id
          )
        ) {
          // reason
          this.appointment.name_canceled_reason = this.reason.filter(
            (s) => s.value == this.appointment.canceled_reason_id
          )[0].label;
        }
      }

      //Name
      this.title_calendar = this.appointment.name;
    },
    populateReason(_data) {
      this.reason = this.$utils.populardropdown(
        _data.content,
        "name",
        "id",
        false,
        false
      );
    },
    populateCompanies(_data) {
      this.companies = this.$utils.populardropdown(
        _data.content,
        "name",
        "id",
        false,
        false
      );
    },

    populateClassifications(_data) {
     this.classifications = _data?.content;
    },

    goAppointment(id) {
      window.location.href = "/pedagogic/calendar?q=" + id;
    },
    getVariant(_item) {
      let variant = "";
      switch (_item.action_code) {
        case 1:
        case 4:
        case 14:
        case 18:
          variant = "success";
          break;

        case 2:
        case 19:
          variant = "warning";
          break;

        case 5:
        case 6:
        case 7:
        case 9:
        case 11:
        case 12:
          variant = "danger";
          break;

        case 8:
        default:
          variant = "secondary";
          break;
      }
      return variant;
    },
    getIcon(_item) {
      let icon = "";
      switch (_item.action_code) {
        case 1:
          icon = "CheckIcon";
          break;
        case 2:
          icon = "Edit3Icon";
          break;
        case 4:
          icon = "UserIcon";
          break;
        case 5:
          icon = "HeadphonesIcon";
          break;
        case 6:
          icon = "FilmIcon";
          break;
        case 7:
          icon = "LinkIcon";
          break;
        case 8:
          icon = "CalendarIcon";
          break;
        case 9:
          icon = "GitCommitIcon";
          break;
        case 11:
          icon = "CodesandboxIcon";
          break;
        case 11:
          icon = "CodesandboxIcon";
          break;
        case 12:
          icon = "FrownIcon";
          break;
        case 14:
          icon = "Edit3Icon";
          break;
        case 18:
          icon = "CheckIcon";
          break;
        case 19:
          icon = "Edit3Icon";
          break;
        default:
          icon = "CheckIcon";
          break;
      }
      return icon;
    },
    getdsDate(_data) {
      return format(parseISO(_data), "dd/MM/yyyy HH:mm") + "h";
    },
    getHistories() {
      if (this.histories.length <= 0) {
        this.isloading = true;
        _appointmentService
          .getHistoric(this.id)
          .then((res) => {
            this.histories = res.content;
          })
          .catch((error) => this.$utils.toastError("Notificação", error))
          .finally(() => (this.isloading = false));
      }
    },
    inputurl(_txt, _index) {
      if (this.links.length <= 0) {
        this.isDisableSaveYoutube = true;
      }
      if (_index <= 0 && _txt) {
        this.isDisableSaveYoutube = false;
        //this.alertSaveYoutube();
      }
    },
    inputUrlTitle(_txt, _index) {
      if (_index >= 0 && _txt) {
        this.isDisableSaveYoutube = false;
        this.alertSaveYoutube();
      }
    },
    onClickCancel(childs = false) {
      if (!this.reasonSelected) {
        this.$utils.toastError("OPS!!!", "Informe o motivo");
        return;
      }

      const payload = {
        reason_id: this.reasonSelected.value,
        reason_desc: this.reason_desc,
        appointment_id: this.id,

        cancel_childs_confirm: childs,
      };

      this.isloading = true;
      const eventOrLesson =
        this.appointment.lesson_id > 0
          ? _lessonService.cancelWithReason(payload)
          : _eventService.cancelWithReason(payload);

      eventOrLesson
        .then(() => {
          this.$utils.toast("Cancelamento", "Sucesso ao cancelar.");
          this.appointment.active = false;
          this.$emit("cancel");
        })
        .catch((error) => {
          if (error && error.split("-")[0] == "cancel_childs_confirm") {
            this.appointment_childs = error.split("-")[1].split(",");
            this.showCancelChilds = true;
          } else {
            this.$utils.toastError("Notificação", error);
          }
        })
        .finally(() => (this.isloading = false));
    },

    confirmSetpartial() {
      const payload = {
        description: this.appointment.app_description,
        name: this.appointment.app_name,
        id: this.id,
      };

      this.isloading = true;
      _appointmentService
        .updatePartial(payload)
        .then(() => {
          this.$utils.toast("Notificação", "Salvo com sucesso");
          this.getRecord();
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.isloading = false));
    },
    getShortStudios() {
      this.studioLoading = true;
      if (this.appointment.event_id != null) {
        _eventService
          .showEventDetails(this.appointment.event_id, this.appointment.id)
          .then((res) => {
            if (res.content) this.eventStudios = res.content;
            this.questionsTotal = {
              question_number: this.eventStudios[0].subevents.reduce((acc, subevent) => {
                return acc + parseInt(subevent.question_number);
              }, 0),
              reused_question_number: this.eventStudios[0].subevents.reduce((acc, subevent) => {
                return acc + parseInt(subevent.reused_question_number);
              }, 0)
            }
            this.displayQuestions = this.questionsTotal.question_number > 0;
          })
          .catch((error) => this.$utils.toastError("Notificação", error))
          .finally(() => (this.studioLoading = false));
      }
      if (this.appointment.lesson_id != null) {
        _lessonService
          .showLessonDetails(this.appointment.event_id, this.appointment.id)
          .then((res) => {
            if (res.content) this.lessonStudio = res.content;
          })
          .catch((error) => this.$utils.toastError("Notificação", error))
          .finally(() => (this.studioLoading = false));
      }
    },
    getShortLinks() {
      if (this.id && this.id > 0 && this.links.length <= 0) {
        this.isloading = true;
        _appointmentService
          .find_short_links(this.id)
          .then((res) => {
            if (res.content) {
              this.links = res.content.map((m) => {
                const transmission_type = this.jobTypeTransmissionList.some(
                  (s) => s.value == m.transmission_type_id
                )
                  ? this.jobTypeTransmissionList.filter(
                    (s) => s.value == m.transmission_type_id
                  )[0]
                  : { value: "-1", label: "Sem Tipo transmissão" };

                return {
                  ...m,
                  jobTypeTransmissionSelected: transmission_type,
                  sync_youtube_before_time: m.sync_youtube,
                  key: this.$utils.getGuidId(),

                  companieSelected: m.company_id
                    ? this.companies.filter((f) => f.value == m.company_id)[0]
                    : { value: "-1", label: "Sem Empresa" },
                  aovivo: {
                    label: m.ds_visibility,
                    value: m.visibility,
                  },
                };
              });
            }
          })
          .catch((error) => this.$utils.toastError("Notificação", error))
          .finally(() => (this.isloading = false));
      }
    },

    getOperatorOptions(_list) {
      return this.$utils.populardropdown(_list, "name", "id", false, false);
    },
    getModeratorOptions(_list) {
      return this.$utils.populardropdown(_list, "name", "id", false, false);
    },
    onChangeOperator(_selected, _studio) {
      const payload = {
        data: {
          appointment_studio_id: _studio.id,
          operator_id: _selected.value,
          studio_id: _studio.studio_id,
        },
      };

      this.loadingop = true;
      _appointmentService
        .createOperator(payload)
        .then((res) => {
          this.loadingop = false;
          this.$utils.toast("Operador", "Salvo com Sucesso");
        })
        .catch((error) => {
          this.loadingop = false;
          this.$utils.toastError("Notificação", error);
        })
        .finally(() => (this.loadingop = false));
    },
    saveModerator(_selected, _studio) {
      const payload_moderator = {
        data: {
          id: _studio.id,
          moderator_id: _selected.value,
        },
      };

      this.loadingopbk = true;
      _appointmentStudioService
        .update(payload_moderator)
        .then((res) => {
          this.loadingopbk = false;
          this.$utils.toast("Moderador", "Salvo com Sucesso");
        })
        .catch((error) => {
          this.loadingopbk = false;
          this.$utils.toastError("Notificação", error);
        })
        .finally(() => (this.loadingop = false));
    },
    saveNewLink(_item) {
      const _payload = {
        data: {
          transmission_type_id: _item.jobTypeTransmissionSelected.value,
          company_id: _item.companieSelected.value,
          appointment_id: this.appointment.id,
          youtube_title: _item.youtube_title,
          youtube_url: _item.youtube_url,
          visibility: _item.aovivo.value,
          id: _item.id,
        },
      };

      const _createOrUpdate =
        _item.id > 0
          ? _appointmentLinkService.update(_payload)
          : _appointmentLinkService.create(_payload);

      this.isloading = true;
      _createOrUpdate
        .then((res) => {
          _item.id = res.content.id;
          this.syncLinkCalendar(this.appointment.id);
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.isloading = false));
    },
    saveTitleGooleCalendar() {
      const _payload = {
        appointment_id: this.appointment.id,
        appointment_calendar_title: this.title_calendar,
      };

      this.isloading = true;
      _appointmentService
        .sync_title_calendar(_payload)
        .then((res) => {
          this.$utils.toast(
            "Notificação",
            "Título do Google Calendário alterado com sucesso"
          );
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.isloading = false));
    },
    removeLink(_item) {
      if (_item.id > 0) {
        this.isloading = true;
        _appointmentLinkService
          .delete(_item.id)
          .then((res) => {
            this.links = this.links.filter((f) => f.key !== _item.key);
            this.$utils.toast("Notificação", "Removido com sucesso");
          })
          .catch((error) => this.$utils.toastError("Notificação", error))
          .finally(() => (this.isloading = false));
      } else {
        this.links = this.links.filter((f) => f.key !== _item.key);
      }
    },
    isDisable(url) {
      return url ? false : true;
    },
    syncLinkCalendar(appointment_id) {
      this.isloading = true;
      _appointmentService
        .sync_link_calendar(appointment_id)
        .then((rs) => {
          this.$utils.toast("Notificação", "");
          this.getShortLinks();
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.isloading = false));
    },
    isNewEntryAvailable({ label, amount }) {
      const currentLinksTypeTotal = this.links.filter(l => l.jobTypeTransmissionSelected.label === label)?.length;
      if (currentLinksTypeTotal < amount) return true
      return false
    },
    addNewLink(_transmission) {
      const hasLimit = this.isNewEntryAvailable(_transmission)
      if (!hasLimit) {
        this.$utils.toastError(
          "Notificação",
          `Limite máximo para ${_transmission.label} atingido.`
        );
        return
      }
      this.links.push({
        jobTypeTransmissionSelected: { ..._transmission },
        companieSelected: this.companies.filter(
          (f) => f.value == this.appointment.company_id
        )[0],
        aovivo: null,
        youtube_title: "",
        youtube_url: "",
        key: this.$utils.getGuidId(),
        id: 0,
      });
    },
    validateLinks() {
      this.validate = true;
      // array de urls
      const _lnkVV = this.links.map((m) => m.url);
      // array de titulos
      const _titVV = this.links.map((m) => m.url_title);
      // array sem duplicados
      const unique = _lnkVV.filter((v, i, a) => a.indexOf(v) === i);
      // array sem vazios
      const validLinks = _lnkVV.filter((l) => l != "");
      const validTit = _titVV.filter((l) => l != "");
      // array de controle do jobtype
      let amountPerType = [];
      let amountPerTypeResult = [];

      // if (unique.length < _lnkVV.length) {
      //   this.$utils.toastError("Notificação", "Não pode usar links iguais.");
      //   this.validate = false;
      //   return;
      // } else if (validTit.length < _titVV.length) {
      //   this.$utils.toastError("Notificação", "Campo de Título vazio");
      //   this.validate = false;
      //   return;
      // }

      // links possíveis por type
      this.jobTypeTransmissionList.forEach(function (s, i) {
        amountPerType.push({
          transmission_type_id: s.value,
          transmission_type_name: s.label,
          amount: s.amount,
        });
      });

      this.links.forEach((fe) => {
        fe.visibility = fe.aovivo.value;
        if (
          !fe.jobTypeTransmissionSelected ||
          !fe.jobTypeTransmissionSelected.value
        ) {
          this.$utils.toastError(
            "Notificação",
            "Selecione o tipo de transmissão."
          );
          this.validate = false;
        } else if (!fe.companieSelected || !fe.companieSelected.value) {
          this.$utils.toastError("Notificação", "Selecione a empresa.");
          this.validate = false;
        } else {
          fe.transmission_type_id = fe.jobTypeTransmissionSelected.value;
          fe.company_id = fe.companieSelected.value;
          if (!fe.company_id) {
            fe.company_id = fe.companieSelected[0].value;
          }
          if (
            !fe.transmission_type_id &&
            fe.jobTypeTransmissionSelected &&
            fe.jobTypeTransmissionSelected.length > 0
          ) {
            fe.transmission_type_id = fe.jobTypeTransmissionSelected[0].value;
          }

          // links atuais a serem descontados
          amountPerType.push({
            transmission_type_id: fe.jobTypeTransmissionSelected.value,
            transmission_type_name: fe.jobTypeTransmissionSelected.label,
            amount: -1,
          });

          // links com somatorio por type negativo, extrapolam a quantidade possível
          amountPerTypeResult = Object.values(
            amountPerType.reduce(
              (r, o) => (
                r[o.transmission_type_id]
                  ? (r[o.transmission_type_id].amount += o.amount)
                  : (r[o.transmission_type_id] = { ...o }),
                r
              ),
              {}
            )
          ).filter((f) => f.amount < 0);

          if (amountPerTypeResult.length > 0) {
            this.$utils.toastError(
              "Notificação",
              "O tipo de agendamento não comporta essa quantidade de '" +
              amountPerTypeResult[0].transmission_type_name +
              "'(s) selecionados."
            );
            this.validate = false;
          }
        }
      });
    },
    //CHAT
    sendMessage() {
      if (this.chatInputMessage) {
        this.isloading = true;

        const payload = {
          data: {
            message: this.chatInputMessage,
            appointment_id: this.id,
          },
        };

        _appointmentService
          .createChat(payload)
          .then((res) => {
            this.chatData.chat.chat.push({
              message: this.chatInputMessage,
              time: new Date(),
              senderId: this.chatData.contact.id,
              userName: res.content,
              userNameInitial: this.getinitials(res.content),
            });
            this.chatInputMessage = "";
          })
          .catch((error) => this.$utils.toastError("Notificação", error))
          .finally(() => (this.isloading = false));
      }
    },
    psToBottom() {
      const scrollEl = this.$refs.refChatLogPS.$el || this.$refs.refChatLogPS;
      scrollEl.scrollTop = scrollEl.scrollHeight;
    },
    getChat() {
      this.isloading = true;
      this.chatData = {
        chat: {
          id: 0,
          chat: [],
        },
        contact: {
          id: 0,
        },
      };

      this.chatData.chat.id = this.id;
      _appointmentService
        .getChat(this.id)
        .then((res) => {
          this.chatData.contact.id = res.content.user_id;
          res.content.chats.forEach((chat) => {
            this.chatData.chat.chat.push({
              message: chat.message,
              time: chat.created_at,
              senderId: chat.user_id,
              userName: chat.user_name,
              userNameInitial: this.getinitials(chat.user_name),
            });
          });
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.isloading = false));
    },
    initChat() { },
    alertSaveYoutube() {
      if (!this.iswarningsaveYoutube) {
        this.iswarningsaveYoutube = true;
        // this.$swal({
        //   title: "Lembrete !!!",
        //   text: "Qualquer alteração nos links do youtube, tanto para adicionar ou remover, é necessário clicar em 'salvar', para concretizar.",
        //   icon: "warning",
        //   confirmButtonText: "Estou ciente!",
        //   customClass: {
        //     confirmButton: "btn btn-info",
        //     cancelButton: "btn btn-outline-danger ml-1",
        //   },
        //   buttonsStyling: false,
        // });
      }
    },
    getinitials(name) {
      const ii = name.split(" ");
      if (ii.length >= 2) {
        return ii[0].substring(0, 1) + ii[1].substring(0, 1);
      } else {
        return ii[0].substring(0, 2);
      }
    },
    changeVisibilityTarget(_item, title, id) {
      if (!this.isOnlyRead) {
        if (id > 0) {
          const payload = {
            visibility: _item.value,
            url_title: title,
            id: id,
          };

          this.loadingTransmission = true;
          _appointmentService
            .updateAvailYoutubeVisibility(payload)
            .then((res) => {
              this.$utils.toast("Notificação", "Salvo com sucesso");
              this.getRecord();
            })
            .catch((error) => this.$utils.toastError("Notificação", error))
            .finally(() => (this.loadingTransmission = false));
        }
      } else {
        this.$utils.toastError(
          "Notificação",
          "Não tem permissão para alterar o alvo da transmissão."
        );
      }
    },
    changeTarget() {
      if (!this.isOnlyRead) {
        const payload = {
          available_youtube: this.aovivo.value === "1",
          youtube_transmission_visibility: this.aovivo.value,
          id: this.appointment.id,
        };

        this.loadingTransmission = true;
        _appointmentService
          .updateAvailYoutube(payload)
          .then((res) => {
            this.$utils.toast("Notificação", "Salvo com sucesso");
            this.getRecord();
          })
          .catch((error) => this.$utils.toastError("Notificação", error))
          .finally(() => (this.loadingTransmission = false));
      } else {
        this.$utils.toastError(
          "Notificação",
          "Não tem permissão para alterar o alvo da transmissão."
        );
      }
    },

    getIconParticipant(par) {
      switch (par.type) {
        case "anchor":
          return "AnchorIcon";
        case "teacher":
          return "UserCheckIcon";
        case "guest":
          return "UsersIcon";
      }
    },
    openPublicEventUrl(url) {
      var a = document.createElement("a");
      a.target = "_blank";
      a.href = url;
      a.click();
    },
    getDropDownVariant(mediaSubjectVideo) {
      if (Array.isArray(mediaSubjectVideo)) {
        if (mediaSubjectVideo.length > 0) {
          if (
            mediaSubjectVideo.some(
              (recordingPeding) =>
                !recordingPeding.status_edition_recording_media
            )
          ) {
            return "info";
          } else {
            return "success";
          }
        } else {
          return "warning";
        }
      }
    },
    getDisciplineName(appointment) {
      if (appointment.discipline_name) return appointment.discipline_name;
      return "";
    },
    getClassificationPlatformName(appointment) {
      if (appointment.classification_name) return appointment.classification_name;
      return "---";
    },
    getNameModerator(moderator) {
      if (moderator) return moderator.name;
      return "---";
    }
  },
};
</script>
<style lang="scss">
@import "@core/scss/base/pages/app-chat-list.scss";

.example {
  display: flex;
  flex-direction: column;
}

.editor {
  height: 20vh;
  padding-bottom: 6%;
}

.lst-conteudo {
  background: #ededed;
  border-radius: 15px;
}

.box-appointment-details {
  border-radius: 15px;
  margin-bottom: 20px !important;
  padding: 10px;
}

.box-appointment-details-bg-light {
  background: #f6f6f6;
}

.box-appointment-details-bg-dark {
  background: #2e353b;
}
</style>
<style>
.dropdown-menu li a {
  display: block;
  width: 150px;
  /* this can be any width you want */
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>