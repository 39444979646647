<template>
  <div
    class="morelinks"
        :class="[
            skin == 'light' ? 'light-mode' : 'dark-mode',
            showDetails && clientWidth < 1740 ? 'position-b' : 'position-a',
        ]"
    >
    <div class="d-flex justify-content-between align-items-center p-50">
        <span class="ml-50 header-title">{{title}}</span>
        <feather-icon
            icon="XIcon"
            style="cursor: pointer"
            class="mr-25"
            @click="close"
        />
    </div>
    <div v-if="links && links.length > 0" class="morelinks-body pt-50 pl-2 pr-2 pb-1">
        <div
            class="links"
            style="cursor: pointer"
            v-for="(link, index) in links"
            :key="index"
            @click="eventClick(link)"
            v-b-tooltip.hover.left.ds1000="
                link.event.extendedProps.start_preparation && link.event.extendedProps.end_preparation ?
                `Preparo ${link.event.extendedProps.start_preparation} às ${link.event.extendedProps.end_preparation}` :
                null
            "
        >
            <span class="link-dot" :style="{backgroundColor: link.event.backgroundColor}"></span> <span>{{link.event.title}}</span>
        </div>
    </div>
  </div>
</template>

<script>
import useAppConfig from "@core/app-config/useAppConfig";
import { VBTooltip } from 'bootstrap-vue'
export default {
    setup () {
        const { skin } = useAppConfig()
        return {
            skin
        };
    },
    directives: {
        'b-tooltip': VBTooltip
    },
    data() {
        return {
            clientWidth: 0
        }
    },
    props: {
        links: {
            type: Array
        },
        title: {
            type: String,
            require: true
        },
        showDetails: {
            type: Boolean,
            default: false
        }
    },
    created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        eventClick(item) {
            this.$emit("eventClick", item);
        },
        close() {
            this.$emit("close");
        },
        handleResize() {
            this.clientWidth = document.documentElement.clientWidth;
        }
    }
}
</script>

<style scoped>
    .dark-mode {
        color: #b4b7bd;
        background-color: #2E353B;
    }
    .light-mode {
        background-color: #ffffff;
    }
    .morelinks {
        min-height: 100px;
        max-height: 500px;
        overflow-y: auto;
        border: solid rgb(221, 221, 221) 1px;
        z-index: 1040;
        position: fixed;
        box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.10);
    }
    .position-a {
        width: 45rem;
        top: 150px;
        left: 270px;
    }
    .position-b {
        width: 30rem;
        top: 150px;
        left: 100px;
    }
    .links {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 5px;
    }
    .links:hover {
        background-color: rgba(185, 185, 185, 0.247);
    }
    .header-title {
        font-weight: bolder;
    }
    .morelinks-body {
        font-size: 0.8rem;
        font-weight: 600;
    }
    .link-dot {
        height: 8px;
        width: 8px;
        border-radius: 50%;
        display: inline-block;
    }
</style>