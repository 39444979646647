import HttpService from './HttpService'

const _http = new HttpService()
export default {
  create: (payload) => {
    return _http.post('/api/appointment-link', payload)
  },
  update: (payload) => {
    return _http.put('/api/appointment-link', payload)
  },
  delete: (id) => {
    return _http.delete(`/api/appointment-link/${id}`)
  },
  showVisibilities: () => {
    return _http.get(`/api/appointment-link/visibilities`)
  }
}